<template>
    <div class="login">
      <div class="login-box">
        <div class="login-left">
          <div class="slogan">找方案 上咋办</div>
          <img src="@/assets/images/login-img.png" alt="">
        </div>
        <div class="form">
          <div class="content">
            <div class="login-title">iKeyhub用户登录</div>
            <el-form :rules="rules" ref="form" size="large" :model="form" @keyup.enter.down="keyDown">
              <el-form-item prop="username">
                <el-input type="text" clearable v-model="form.phone" placeholder="请输入手机号">
                  <template #prefix>
                    <i class="iconfont icon-shouji" size="16"></i>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="form.code" type="text" placeholder="请输入验证码" style="flex:1;margin-right:10px;">
                  <template #prefix>
                    <i class="iconfont icon-mima" size="16"></i>
                  </template>
                </el-input>
                <el-button plain style="height:42px;" @click="sendVerifyCode">{{count_down}}</el-button>
              </el-form-item>
              <el-form-item prop="code_img">
                <el-row class="w100" :gutter="15">
                  <el-col :span="16">
                    <el-input type="text" placeholder="请输入图形中的验证码" v-model="form.code_img" clearable autocomplete="off">
                      <template #prefix>
                        <i class="iconfont icon-yanzhengma" size="16"></i>
                      </template>
                    </el-input>
                  </el-col>
                  <el-col :span="8">
                    <img @click="changeCodeImg" class="code-img" :src="codeUrl + '&id=' + form.codeId" alt="验证码图片"/>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-checkbox v-model="form.keep" label="保持会话" size="default"></el-checkbox>
              <el-form-item>
                <el-button :loading="loading" class="submit-button" type="primary" size="large" @click="login()">
                  登录
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import user from "@/api/user";
import publics from "@/api/public";
import {setToken} from "@/utils/auth";
import {verifyMobile} from "@/utils/validate";
import {uuid} from "@/utils/random";

export default {
  name: "login",
  components:{},
  data() {
    return {
      loading: false,
      codeUrl: '',
      second: 0,
      count_down: '获取验证码',
      captcha_id: '',
      timer: null,
      form: {
        phone: '',
        code: '',
        code_img: '',
        codeId: uuid(),
        keep: true
      },
      rules: {
        phone: [{required: true, message: '请输入用户名', trigger: 'blur'}],
        code: [{required: true, message: '请输入密码', trigger: 'blur'}, {
          min: 4,
          max: 6,
          message: '短信验证码长度在 4 到 6 个字符',
          trigger: 'blur'
        }],
        code_img: [{required: true, message: '请输入图片验证码', trigger: 'blur'}, {
          min: 4,
          max: 6,
          message: '验证码长度在 4 到 6 个字符',
          trigger: 'blur'
        }]
      }
    }
  },
  created() {
    publics.captcha().then(res => {
      this.codeUrl = res;
    });
  },
  methods: {
    //更新二维码图片
    changeCodeImg() {
      this.form.codeId = uuid();
    },
    //enter键提交
    keyDown(e) {
      //console.log('enter');
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.login();
      }
    },
    //倒计时
    countDown() {
      this.timer = setInterval(() => {
        if (this.second > 0) {
          this.second = this.second - 1;
          this.count_down = this.second + 'S';
        } else {
          this.count_down = '获取验证码';
          clearInterval(this.timer);
        }
      }, 1000)
    },
    //发送 短信验证码
    sendVerifyCode() {
      if (this.second > 0) {
        this.$message.error('请勿短时间内频繁操作！');
        return false;
      }
      if (!verifyMobile(this.form.phone)) {
        this.$message.error('请输入正确格式的手机号！');
        return false;
      }
      //倒计时，防误触
      this.second = 60;
      this.countDown();

      publics.sendSms({
        phone: this.form.phone
      }).then(res => {
        this.$message.success(res.msg)
      })
    },
    login() {
      this.loading = true;
      const that = this;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          //管理后台登录
          user.userLoginOrReg({
            username: this.form.phone,
            code: this.form.code
          }).then(res => {
            if (res) {
              setToken(res.data.token);
              this.$store.dispatch('setAccount',res.data.account);
              that.$router.push('/')
            }else{
              that.form.codeId = uuid();
            }
            that.loading = false;
          });
        } else {
          that.form.codeId = uuid();
          that.loading = false;
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.login-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/images/login-bg.jpg");
  background-size: cover;
  background-repeat: repeat;
  background-color: rgba(255, 255, 255, 1);
}

.form-item-icon {
  color: var(--el-input-icon-color);
  height: auto;
}

.login {
  top: 0;
  display: flex;
  /*height: 100vh;*/
  align-items: center;
  justify-content: center;

  .login-box {
    display: flex;align-items: center;justify-content: space-between;
    overflow: hidden;
    width: 728px;
    padding: 0;
    background: var(--el-color-white);
    margin-top: 60px;
    margin-bottom: 80px;
    border-radius: 10px;
    z-index: 99;
  }
  .login-left{width:280px;height:476px;background: #e3eef9;position: relative;
    img{position: absolute;bottom:86px;left:57px;width:163px;opacity: 0.9;}
    .slogan{
    margin-top: 110px;font-family: "阿里妈妈数黑体";font-size: 24px;font-weight:700;color:#000;text-align: center;letter-spacing:3px; }
  }
  .form {width:430px;
    position: relative;

    .content {
      padding: 40px 40px 40px 40px;
      .login-title{
        text-align: center;
        text-align: -moz-center;
        text-align: -webkit-center;
        font-size: 20px;
        font-weight: bold;
        color: #2655a5;
        padding: 20px 20px 30px 20px;
      }
    }

    .submit-button {
      width: 100%;
      letter-spacing: 2px;
      font-weight: 300;
      margin-top: 15px;
      --el-button-bg-color: var(--el-color-primary);
    }
  }
}

@media screen and (max-width: 720px) {
  .login {
    display: flex;
    align-items: center;
    justify-content: center;

    .login-box {
      width: 340px;
      margin-top: 0;
    }
  }
}

.chang-lang :deep(.el-dropdown-menu__item) {
  justify-content: center;
}

.code-img {
  width: 100%;
}

@media screen and (max-height: 800px) {
  .login .login-box {
    margin-bottom: 0;
  }
}
.footer{  position: absolute;left: 50%;right: 50%;transform: translateX(-50%);bottom: 0;}

</style>